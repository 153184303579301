import React from "react"
import { Box, Text } from "grommet"
import { Layout, SEO } from "../../components"

const Page = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Box pad="medium">
        <Text>Hello</Text>
      </Box>
    </Layout>
  )
}

export default Page
